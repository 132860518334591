import { render, staticRenderFns } from "./ImageGrid.vue?vue&type=template&id=bac6cf0a&scoped=true&"
import script from "./ImageGrid.vue?vue&type=script&lang=js&"
export * from "./ImageGrid.vue?vue&type=script&lang=js&"
import style0 from "./ImageGrid.vue?vue&type=style&index=0&id=bac6cf0a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bac6cf0a",
  null
  
)


    import installComponents from "!/home/runner/_work/rentmagic.website.vue.west-vlaanderen/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BImg, BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BImg, BCol, BRow})
    

export default component.exports