import { render, staticRenderFns } from "./sidebar.vue?vue&type=template&id=345f517a&scoped=true&"
import script from "./sidebar.vue?vue&type=script&lang=js&"
export * from "./sidebar.vue?vue&type=script&lang=js&"
import style0 from "./sidebar.vue?vue&type=style&index=0&id=345f517a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345f517a",
  null
  
)


    import installComponents from "!/home/runner/_work/rentmagic.website.vue.west-vlaanderen/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BCollapse} from 'bootstrap-vue'
    installComponents(component, {BButton, BCollapse})
    


    import installDirectives from "!/home/runner/_work/rentmagic.website.vue.west-vlaanderen/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBToggle} from 'bootstrap-vue'
    installDirectives(component, {'b-toggle': VBToggle})
    

export default component.exports