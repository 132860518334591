import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=3db05158&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3db05158",
  null
  
)


    import installComponents from "!/home/runner/_work/rentmagic.website.vue.west-vlaanderen/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BFormSelect, BButton, BButtonGroup, BRow} from 'bootstrap-vue'
    installComponents(component, {BCol, BFormSelect, BButton, BButtonGroup, BRow})
    

export default component.exports